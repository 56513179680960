<template>
    <BaseContainer>
        <span slot="principal">
            <v-content class="pr-2 pl-2" style="background-color: #e0e0e0;">
                <v-toolbar class="ma-2" style="border-radius: 30px;">
                    <v-btn icon link to="/">
                        <v-icon>mdi-arrow-left</v-icon>
                    </v-btn>
                    <v-spacer/>
                    <v-toolbar-title v-show="$vuetify.breakpoint.smAndUp">Produtos</v-toolbar-title>
                    <v-spacer/>
                    <v-text-field
                        dense
                        clearable
                        outlined
                        single-line
                        hide-details
                        v-model="search"
                        label="Pesquise.."
                        append-icon="mdi-magnify"
                    />
                    <v-spacer/>
                    <v-menu offset-y v-if="!unlockType">
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn :loading="saving" icon v-bind="attrs" v-on="on">
                                <v-tooltip v-if="isAdmin" bottom>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-icon  v-bind="attrs" v-on="on">mdi-lock-outline</v-icon>
                                    </template>
                                    <span>Desbloquear edição</span>
                                </v-tooltip>
                            </v-btn>
                        </template>
                        <v-list>
                            <v-list-item @click="unlockType = 'ordenacao'">
                                <v-list-item-content>
                                    <v-list-item-title>Desbloquear Ordenação</v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item @click="unlockType = 'preco'">
                                <v-list-item-content>
                                    <v-list-item-title>Desbloquear Edição de Preço</v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item @click="unlockType = 'estoque'">
                                <v-list-item-content>
                                    <v-list-item-title>Desbloquear Edição de Estoque</v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                        </v-list>
                    </v-menu>
                    <v-btn v-if="unlockType" @click="save()" color="primary" icon>
                        <v-icon>mdi-content-save</v-icon>
                    </v-btn>
                    <v-spacer/>
                    <ButtonAddNew v-if="isAdmin" @click="openCadastro" />
                </v-toolbar>
                <v-card elevation="2" class="ma-1">
                    <v-card-text :style="`height: ${$vuetify.breakpoint.height - 150}px; overflow-x: auto;`">
                        <v-skeleton-loader
                            v-if="loading"
                            class="mx-auto"
                            type="card"
                            height="100"
                        />
                        <v-expansion-panels v-model="panel" v-else>
                            <draggable
                                v-model="dados"
                                style="width: 100%;"
                                :disabled="unlockType !== 'ordenacao'"
                            >
                                <v-expansion-panel v-for="(grupo, i) in grupos" :key="i">
                                    <v-expansion-panel-header>
                                        <div style="display: flex; align-items: flex-end;">
                                            <div>
                                                <v-btn icon style="cursor: move;" :disabled="unlockType !== 'ordenacao'">
                                                    <v-icon>mdi-drag-vertical</v-icon>
                                                </v-btn>
                                            </div>
                                            <div class="mb-1">
                                                <v-switch
                                                    v-model="grupo.status"
                                                    inset
                                                    hide-details
                                                    dense
                                                    class="ml-4"
                                                    true-value="ativo"
                                                    false-value="inativo"
                                                    @click.stop
                                                    @change="onUpdateStatusGrupo(grupo)"
                                                />
                                            </div>
                                            <div v-if="!grupo.editDescGrupo" class="ml-5 mb-2" style="font-weight: bold; text-transform: uppercase;">
                                                <span>{{ grupo.descricao }}</span>
                                            </div>
                                            <div v-else>
                                                <v-text-field
                                                    dense
                                                    outlined
                                                    single-line
                                                    hide-details
                                                    v-model="grupo.descricao"
                                                />
                                            </div>
                                            <v-spacer/>
                                            <v-btn
                                                @click.stop="editarDescricaoGrupo(grupo)"
                                                :disabled="!isAdmin"
                                                icon
                                                color="primary"
                                                class="mb-2 mr-2"
                                                title="Editar descrição"
                                                small
                                            >
                                                <v-icon small>mdi-{{grupo.editDescGrupo ? 'content-save-outline' : 'pencil'}}</v-icon>
                                            </v-btn>
                                        </div>
                                    </v-expansion-panel-header>
                                    <v-expansion-panel-content>
                                        <div class="product-header">
                                            <div class="product-column sm-column v-label theme--light"></div>
                                            <div class="product-column v-label theme--light">Descrição</div>
                                            <div class="product-column v-label theme--light">Preço</div>
                                            <div v-if="showEstoque" class="product-column v-label theme--light" style="height: 10px;">Estoque</div>
                                            <div class="product-column sm-column v-label theme--light">Ativo</div>
                                            <div class="product-column sm-column v-label theme--light">Duplicar</div>
                                            <div class="product-column sm-column v-label theme--light">Excluir</div>
                                        </div>

                                        <draggable
                                            v-model="grupo.produtos"
                                            :disabled="unlockType !== 'ordenacao'"
                                        >
                                            <div
                                                class="product-row"
                                                v-for="(produto, ia) in grupo.produtos"
                                                :key="ia"
                                                @click="editar(produto)"
                                            >
                                                <div class="mt-1 product-column sm-column">
                                                    <v-btn icon style="cursor: move;" :disabled="unlockType !== 'ordenacao'">
                                                        <v-icon>mdi-drag-vertical</v-icon>
                                                    </v-btn>
                                                </div>
                                                <div class="ta-l mt-3 mb-3 product-column" style="width: 50%;">
                                                    <v-tooltip
                                                        v-if="hasFiscalModule && !produto.categoria_imposto_id"
                                                        bottom
                                                    >
                                                        <template v-slot:activator="{ on, attrs }">
                                                            <v-icon
                                                                v-bind="attrs"
                                                                v-on="on"
                                                                color="warning"
                                                                class="mb-1"
                                                                small
                                                            >mdi-alert-outline</v-icon>
                                                        </template>
                                                        <span>Produto sem categoria de imposto</span>
                                                    </v-tooltip>
                                                    {{ produto.descricao }}
                                                </div>
                                                <div class="ta-l mt-3 mb-3 product-column">
                                                    <span v-if="unlockType !== 'preco'">R$ {{ produto.valor }}</span>
                                                    <v-text-field
                                                        v-else
                                                        v-model="produto.valor"
                                                        v-mask="['#.##', '##.##', '###.##', '####.##']"
                                                        outlined
                                                        dense
                                                        hide-details
                                                        @click.stop=""
                                                        class="price"
                                                        style="width: 80px;"
                                                    />
                                                </div>
                                                <div v-if="showEstoque" class="ta-l mt-3 mb-3 product-column">
                                                    <span v-if="unlockType !== 'estoque' && produto.controle_estoque">{{ produto.estoque }}</span>
                                                    <v-text-field
                                                        v-if="unlockType === 'estoque'  && produto.controle_estoque"
                                                        v-model="produto.estoque"
                                                        v-mask="['#####']"
                                                        outlined
                                                        dense
                                                        hide-details
                                                        @click.stop=""
                                                        class="price"
                                                        style="width: 100px;"
                                                    />
                                                    <span v-if="!produto.controle_estoque">Desabilitado</span>
                                                </div>
                                                <div class="product-column sm-column">
                                                    <v-switch
                                                        v-model="produto.status"
                                                        inset
                                                        hide-details
                                                        dense
                                                        true-value="ativo"
                                                        false-value="inativo"
                                                        @click.stop
                                                        @change="onUpdateAtivo(produto)"
                                                        style="margin-top: 8px;"
                                                    />
                                                </div>
                                                <div class="mt-1 product-column sm-column">
                                                    <v-btn
                                                        icon
                                                        @click.stop="duplicar(produto)"
                                                        :disabled="!isAdmin"
                                                    >
                                                        <v-icon>mdi-plus-circle-multiple-outline</v-icon>
                                                    </v-btn>
                                                </div>
                                                <div class="mt-1 product-column sm-column">
                                                    <v-btn
                                                        icon
                                                        color="error"
                                                        @click.stop="excluir(produto.id)"
                                                        :disabled="!isAdmin"
                                                    >
                                                        <v-icon>mdi-delete</v-icon>
                                                    </v-btn>
                                                </div>
                                            </div>
                                        </draggable>
                                    </v-expansion-panel-content>
                                </v-expansion-panel>
                            </draggable>
                        </v-expansion-panels>
                    </v-card-text>
                </v-card>
            </v-content>
            <Produto
                :visible="visible"
                @newGrupo="consultar"
                @onCadastro="getById($event)"
                @close="close"
            />
        </span>
    </BaseContainer>
</template>

<script>
import BaseContainer from '@/components/BaseContainer';
import ButtonAddNew from '@/components/ButtonAddNew';
import Produto from '@/pages/cadastro/Produto';
import draggable from 'vuedraggable';
import { mapState, mapMutations } from 'vuex';
import { hasModule } from '@/utils/module';

export default {
    name: 'Produtos',

    components: {
        BaseContainer,
        ButtonAddNew,
        Produto,
        draggable,
    },

    data: () => ({
        search: '',
        panel: '',
        unlockType: null, // 'ordenacao', 'preco' ou 'estoque'
        loading: true,
        saving: false,
        dados: [],
        dialog: false,
        loadingSalvar: false,
        visible: false,
    }),

    created() {
        this.consultar();
    },

    computed: {
        ...mapState([
            'usuario',
            'configuracoes',
        ]),

        isAdmin() {
            return this.usuario.admin;
        },

        grupos() {
            if (!this.search) {
                return this.dados;
            }
            const grupos = JSON.parse(JSON.stringify(this.dados));

            return grupos.filter(g => {
                g.produtos = g.produtos.filter(
                    p => p.descricao.toLocaleLowerCase().includes(this.search.toLocaleLowerCase())
                );
                return g.produtos.length;
            });
        },

        showEstoque() {
            return this.$vuetify.breakpoint.mdAndUp && this.configuracoes.controle_estoque;
        },

        hasFiscalModule() {
            return hasModule('FISCAL');
        },
    },

    methods: {
        ...mapMutations({
            setDashboardInitialized: 'setDashboardInitialized',
        }),

        consultar() {
            this.loading = true;
            this.$http.get('grupos?with-produtos=1').then(resp => {
                this.dados = resp.data.data;
                this.dados.map(g => g.editDescGrupo = false);
                this.loading = false;
            })
            .catch(() => {
                this.notify('Verifique sua conexão com a internet', 'warning');
                this.loading = false;
            });
        },

        getById(id) {
            this.$root.$emit('loading', true);
            this.$http.get(`produtos/${id}`).then(({ data }) => {
                const grupo = this.dados.find(grupo => grupo.id === data.grupo_id);
                const produtoIndex = grupo.produtos?.findIndex(produto => produto.id === data.id);
                if (produtoIndex !== -1) {
                    grupo.produtos[produtoIndex] = { ...grupo.produtos[produtoIndex], ...data };
                } else {
                    grupo.produtos.push(data);
                }
                this.$forceUpdate();
            })
            .catch(() => {
                this.notify('Verifique sua conexão com a internet', 'warning');
            })
            .finally(() => (this.$root.$emit('loading', false)));
        },

        close() {
            this.visible = false;
        },

        openCadastro() {
            this.visible = true;
        },

        editar(row) {
            this.$store.commit('setData', row);
            this.visible = true;
        },

        duplicar(row) {
            this.$store.commit('setData', { ...row, ...{ duplicar: true } });
            this.visible = true;
        },

        async excluir(id) {
            if (await this.$root.$confirm('Confirmação', 'Deseja realmente excluir?', { color: 'red darken-1' })) {
                this.$http.delete(`produtos/${id}`).then(() => {
                    this.notify('Excluído com sucesso!');
                    this.setDashboardInitialized(false);
                    this.consultar();
                })
                .catch(() => {
                    this.notify('Verifique sua conexão com a internet', 'warning');
                });
            }
        },

        save() {
            const grupos = this.dados.map((g, index) => ({
                id: g.id,
                ordem: index + 1,
            }));
            const produtos = this.dados.map(g =>
                g.produtos.map((p, index) => ({
                    id: p.id,
                    ordem: index + 1,
                    valor: p.valor,
                    estoque: p.estoque,
                }))
            ).flat(1);

            this.saving = true;
            this.$http.post(`produtos/update-many`, { grupos, produtos }).then(() => {
                this.notify('Atualizado com sucesso!');
                this.setDashboardInitialized(false);
            }).catch(() => {
                this.notify('Verifique sua conexão com a internet', 'warning');
            }).finally(() => (this.saving = false));
            this.unlockType = null;
        },

        onUpdateAtivo(produto) {
            this.$http.put(`produtos/update-status/${produto.id}`, { status: produto.status }).then(() => {
                this.notify('Atualizado com sucesso!');
                this.setDashboardInitialized(false);
            }).catch(() => {
                this.notify('Verifique sua conexão com a internet', 'warning');
            });
        },

        onUpdateStatusGrupo(grupo) {
            this.$http.put(`grupos/update-status/${grupo.id}`, { status: grupo.status }).then(() => {
                this.notify('Atualizado com sucesso!');
                this.setDashboardInitialized(false);
            }).catch(() => {
                this.notify('Verifique sua conexão com a internet', 'warning');
            });
        },

        editarDescricaoGrupo(grupo) {
            if (grupo.editDescGrupo) {
                this.$http.put(`grupos/${grupo.id}`, grupo).then(() => {
                    this.notify('Atualizado com sucesso!');
                    this.setDashboardInitialized(false);
                }).catch(() => {
                    this.notify('Verifique sua conexão com a internet', 'warning');
                });
            }

            this.dados.map(g => g.editDescGrupo = (!grupo.editDescGrupo && g.id == grupo.id));
            this.$forceUpdate();
        },

        updateEstoque({ id, estoque }) {
            this.$http.put(`produtos/update-estoque/${id}`, { estoque }).then(() => {
                this.setDashboardInitialized(false);
                this.notify('Atualizado com sucesso!');
            }).catch(() => {
                this.notify('Verifique sua conexão com a internet', 'warning');
            });
        },
    },
}
</script>

<style scoped>
.product-header {
    display: flex;
    justify-content: space-between;
    font-size: 16px;
    margin-bottom: 10px;
}

.product-row {
    display: flex;
    justify-content: space-between;
    font-size: 16px;
    border-top: 1px solid #ddd;
}

.product-row:hover {
    background: #ddd;
    border-radius: 2px;
}

.product-column {
    margin: 0 2px;
    flex: 1;
}

.sm-column {
    margin: 0 2px;
    flex: 0.25;
}

.v-expansion-panel-content__wrap {
    padding: 1px !important;
}

.ta-l {
    text-align: left;
}

::v-deep .price fieldset {
    height: 35px;
}

::v-deep .price .v-input__control {
    height: 25px;
    margin-top: -5px;
}

::v-deep .price input {
    padding: 8px 0 18px;
}
</style>
